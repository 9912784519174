<style>
.bgc3 {
  background: lightgreen;
}
</style>
<template>
  <div class="sale-table">
    <v-data-table
      :items="this.dataArray.items"
      :headers="headers"
      :loading="loading"
      :server-items-length="this.dataArray.items.length"
      hide-default-footer
      loading-text="Загрузка..."
      no-data-text="Нет записей"
      no-results-text="Записи не найдены"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.title }}</td>
          <td>{{ item.article }}</td>
          <td>{{ item.price }}</td>
          <td>{{ item.total.count }}</td>
          <td>{{ item.total.money.toFixed(2).toString() }}</td>
        </tr>
      </template>
    </v-data-table>
    <div class="px-4 font-weight-medium">Итого: {{ this.dataArray.total.money.toFixed(2).toString() }} руб.</div>
  </div>
</template>

<script>
export default {
  props: {
    dataArray: Object,
    loading: Boolean
  },
  data() {
    return {
      headers: [
        {
          text: 'Предзаказ',
          value: 'title',
          sortable: false,
          class: 'bgc3'
        },
        {
          text: 'Артикул',
          value: 'article',
          sortable: false,
          class: 'bgc3'
        },
        {
          text: 'Цена, руб.',
          value: 'price',
          sortable: false,
          class: 'bgc3'
        },
        {
          text: 'Количество',
          value: 'count',
          sortable: false,
          class: 'bgc3'
        },
        {
          text: 'Сумма с НДС, руб.',
          value: 'money',
          sortable: false,
          class: 'bgc3'
        }
      ]
    }
  }
}
</script>
