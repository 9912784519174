<style>
.box {
  clear: both;
  padding: 0;
  min-height: 50px;
  height: 50vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
table.scrolling .w1 {
  width: 20em;
  min-width: 20em;
  max-width: 20em;
}
table.scrolling .bgc {
  background: lightpink;
}
table.scrolling .bgc2 {
  background: lightblue;
}
table.scrolling .bgc3 {
  background: lightgreen;
}
table.scrolling .w1all {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
}
table.scrolling .w2 {
  width: 5em;
  min-width: 5em;
  max-width: 5em;
}
table.scrolling .w3 {
  width: 10em;
  min-width: 10em;
  max-width: 10em;
}
table.scrolling .alr {
  text-align: right;
}
table.scrolling .total {
  font-weight: bold;
}
table.scrolling tfoot tr th {
  width: 130em;
  min-width: 130em;
  max-width: 130em;
}
table.freezeFirstColumn thead tr,
table.freezeFirstColumn tbody tr {
  display: block;
  width: min-content;
}
table.freezeFirstColumn thead td:first-child,
table.freezeFirstColumn tbody td:first-child,
table.freezeFirstColumn thead th:first-child,
table.freezeFirstColumn tbody th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}
</style>
<template>
  <div class="sale-table">
    <div class="title-2 font-weight-light">Приход</div>
    <div class="mt-1 box">
      <vue-scrolling-table :class="{ freezeFirstColumn: freezeFirstColumn }">
        <template slot="thead">
          <tr>
            <th class="w1">{{ dataArray.locationDescription }}</th>
            <th v-for="period in dataArray.periods" :key="`retro-${period}`" :colspan="2" class="w3">
              {{ period }}
            </th>
            <th :colspan="2" class="w3">Итого</th>
          </tr>
          <tr>
            <th class="w1">Наименование</th>
            <template v-for="period in dataArray.periods">
              <th :key="`retro-col-head-${period}`" class="w2">Кол-во</th>
              <th :key="`retro-sum-head-${period}`" class="w2">Сумма</th>
            </template>
            <th class="w2">Кол-во</th>
            <th class="w2">Сумма</th>
          </tr>
        </template>
        <template slot="tbody">
          <tr>
            <th class="w1 bgc">Услуги</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-services-head-${period}`" class="bgc"></th>
            <th class="bgc"></th>
          </tr>
          <tr v-for="item in dataArray.services.items" :key="`retro-retro-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-services-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-services-${period}`" class="w2 alr">
                {{ item.periods[period].money.toFixed(2).toString() }}
              </td>
            </template>
            <td :key="`retro-col-services-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-services-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого услуги: {{ dataArray.services.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
          <tr>
            <th class="w1 bgc2">Товары</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-goods-head-${period}`" class="bgc2"></th>
            <th class="bgc2"></th>
          </tr>
          <tr v-for="item in dataArray.goods.items" :key="`retro-goods-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-goods-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-goods-${period}`" class="w2 alr">
                {{ item.periods[period].money }}
              </td>
            </template>
            <td :key="`retro-col-goods-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-goods-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого товары: {{ dataArray.goods.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
          <tr>
            <th class="w1 bgc3">Предзаказы</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-preorders-head-${period}`" class="bgc3"></th>
            <th class="bgc3"></th>
          </tr>
          <tr v-for="item in dataArray.preOrders.items" :key="`retro-normal-preorders-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-preorders-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-preorders-${period}`" class="w2 alr">
                {{ item.periods[period].money }}
              </td>
            </template>
            <td :key="`retro-col-preorders-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-preorders-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого предзаказы: {{ dataArray.preOrders.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
        </template>
      </vue-scrolling-table>
    </div>
    <span class="font-weight-bold">Итого: {{ totalMoney.toFixed(2).toString() }} руб.</span>

    <div class="mt-3 title-2 font-weight-light">Возврат прихода</div>
    <div class="mt-1 box">
      <vue-scrolling-table :class="{ freezeFirstColumn: freezeFirstColumn }">
        <template slot="thead">
          <tr>
            <th class="w1">{{ dataArray.locationDescription }}</th>
            <th v-for="period in dataArray.periods" :key="`retro-${period}`" :colspan="2" class="w3">
              {{ period }}
            </th>
            <th :colspan="2" class="w3">Итого</th>
          </tr>
          <tr>
            <th class="w1">Наименование</th>
            <template v-for="period in dataArray.periods">
              <th :key="`retro-col-head-${period}`" class="w2">Кол-во</th>
              <th :key="`retro-sum-head-${period}`" class="w2">Сумма</th>
            </template>
            <th class="w2">Кол-во</th>
            <th class="w2">Сумма</th>
          </tr>
        </template>
        <template slot="tbody">
          <tr>
            <th class="w1 bgc">Услуги</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-services-head-${period}`" class="bgc"></th>
            <th class="bgc"></th>
          </tr>
          <tr v-for="item in dataArray.servicesRefund.items" :key="`retro-retro-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-services-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-services-${period}`" class="w2 alr">
                {{ item.periods[period].money.toFixed(2).toString() }}
              </td>
            </template>
            <td :key="`retro-col-services-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-services-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого услуги: {{ dataArray.servicesRefund.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
          <tr>
            <th class="w1 bgc2">Товары</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-goods-head-${period}`" class="bgc2"></th>
            <th class="bgc2"></th>
          </tr>
          <tr v-for="item in dataArray.goodsRefund.items" :key="`retro-goods-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-goods-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-goods-${period}`" class="w2 alr">
                {{ item.periods[period].money }}
              </td>
            </template>
            <td :key="`retro-col-goods-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-goods-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого товары: {{ dataArray.goodsRefund.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
          <tr>
            <th class="w1 bgc3">Предзаказы</th>
            <th v-for="period in dataArray.periods" :key="`retro-col-preorders-head-${period}`" class="bgc3"></th>
            <th class="bgc3"></th>
          </tr>
          <tr v-for="item in dataArray.preOrdersRefund.items" :key="`retro-normal-preorders-${item.title}-${item.price}`">
            <td class="w1">{{ item.title }}</td>
            <template v-for="period in dataArray.periods">
              <td :key="`retro-col-preorders-${period}`" class="w2 alr">
                {{ item.periods[period].count }}
              </td>
              <td :key="`retro-sum-preorders-${period}`" class="w2 alr">
                {{ item.periods[period].money }}
              </td>
            </template>
            <td :key="`retro-col-preorders-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.count }}
            </td>
            <td :key="`retro-sum-preorders-${item.title}-${item.price}-total`" class="w2 alr">
              {{ item.total.money.toFixed(2).toString() }}
            </td>
          </tr>
          <tr>
            <td class="w1">
              <span class="font-weight-bold"> Итого предзаказы: {{ dataArray.preOrdersRefund.total.money.toFixed(2).toString() }} руб. </span>
            </td>
            <td v-for="period in dataArray.periods" :key="`retro-col-services-total-${period}`"></td>
            <td></td>
          </tr>
        </template>
      </vue-scrolling-table>
    </div>
    <span class="font-weight-bold">Итого: {{ totalRefund.toFixed(2).toString() }} руб.</span>
  </div>
</template>

<script>
import ScrollTable from 'vue-scrolling-table'
export default {
  props: {
    loading: Boolean,
    dataArray: Object
  },
  data() {
    return {
      headers: [
        {
          text: 'Товар',
          value: 'title',
          sortable: false
        },
        {
          text: 'Аритикул',
          value: 'article',
          sortable: false
        },
        {
          text: 'Цена, руб.',
          value: 'price',
          sortable: false
        },
        {
          text: 'Количество',
          value: 'count',
          sortable: false
        },
        {
          text: 'Сумма с НДС, руб.',
          value: 'money',
          sortable: false
        }
      ],
      freezeFirstColumn: true
    }
  },
  computed: {
    totalMoney() {
      return this.dataArray.services.total.money + this.dataArray.goods.total.money + this.dataArray.preOrders.total.money
    },
    totalRefund() {
      return this.dataArray.servicesRefund.total.money + this.dataArray.goodsRefund.total.money + this.dataArray.preOrdersRefund.total.money
    }
  },
  components: {
    'vue-scrolling-table': ScrollTable
  }
}
</script>
