<template>
  <div>
    <h1 class="subtitle-1 grey--text">Статистика продаж</h1>
    <v-row class="grey lighten-3 mx-0">
      <v-col cols="4">
        <v-menu
          ref="dateRangePicker"
          v-model="isDateRangePickerVisible"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="slide-y-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :first-day-of-week="1"
              prepend-inner-icon="mdi-calendar-range"
              hide-details
              readonly
              flat
              solo
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="dateRange" range :max="`${currentDate}`" no-title scrollable @change="onDatePickerRangeChange">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="onDatePickerRangeCancel">Отмена</v-btn>
            <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-select
          class="mt-2"
          v-model="selector"
          :items="selectorItems"
          v-on:change="onChangeSelector"
          item-text="text"
          item-value="value"
          label="Выберите месяц"
          persistent-hint
          return-object
          single-line
          outlined
        ></v-select>
      </v-col>
      <v-col class="d-flex flex-column">
        <label>
          <input type="radio" v-bind:value="5" v-on:change="setDateRange" v-model="dateRadioPicked" />
          С начала эксплуатации
        </label>
        <label>
          <input type="radio" v-bind:value="1" v-on:change="setDateRange" v-model="dateRadioPicked" />
          Текущий месяц
        </label>
        <label>
          <input type="radio" v-bind:value="2" v-on:change="setDateRange" v-model="dateRadioPicked" />
          Неделя
        </label>
        <label>
          <input type="radio" v-bind:value="3" v-on:change="setDateRange" v-model="dateRadioPicked" />
          Вчера
        </label>
        <label>
          <input type="radio" v-bind:value="4" v-on:change="setDateRange" v-model="dateRadioPicked" />
          Сегодня
        </label>
        <label>
          <input type="radio" v-bind:value="0" v-on:change="setDateRange" v-model="dateRadioPicked" />
          Произвольный
        </label>
      </v-col>
      <v-col class="d-flex flex-column">
        <label>
          <input type="radio" v-bind:value="1" v-on:change="setDateRange" v-model="reportTypePicked" />
          Общий
        </label>
        <label>
          <input type="radio" v-bind:value="2" v-on:change="setDateRange" v-model="reportTypePicked" />
          По дням
        </label>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex flex-column">
        <template v-if="salesStatistics.common && reportTypePicked === 1">
          <span class="title-2 font-weight-light">Приход</span>
          <StatisticListServices class="mt-1" :dataArray="salesStatistics.common.services" :loading="isLoading" />
          <span class="mt-3 title-2 font-weight-light">Возврат прихода</span>
          <StatisticListServices class="mt-2" :dataArray="salesStatistics.common.servicesRefund" :loading="isLoading" />
          <v-divider class="mt-2"></v-divider>
          <span class="mt-2 title-2 font-weight-light">Приход</span>
          <StatisticListGoods class="mt-1" :dataArray="salesStatistics.common.goods" :loading="isLoading" />
          <span class="mt-3 title-2 font-weight-light">Возврат прихода</span>
          <StatisticListGoods class="mt-2" :dataArray="salesStatistics.common.goodsRefund" :loading="isLoading" />
          <v-divider class="mt-2"></v-divider>
          <span class="mt-2 title-2 font-weight-light">Приход</span>
          <StatisticListPreOrders class="mt-3" :dataArray="salesStatistics.common.preOrders" :loading="isLoading" />
          <span class="mt-3 title-2 font-weight-light">Возврат прихода</span>
          <StatisticListPreOrders :dataArray="salesStatistics.common.preOrdersRefund" :loading="isLoading" class="mt-2" />
        </template>
        <template v-if="salesStatistics && reportTypePicked === 2">
          <StatisticListGroup v-for="group in salesStatistics.groups" :key="group.location" :dataArray="group" :loading="isLoading" class="mb-3" />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticListGoods from '@/components/statistic/ListGoods'
import StatisticListPreOrders from '@/components/statistic/ListPreOrders'
import StatisticListServices from '@/components/statistic/ListServices'
import StatisticListGroup from '@/components/statistic/ListGroup'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    const today = this.$moment().format('YYYY-MM-DD')
    return {
      isDateRangePickerVisible: false,
      dateRange: [today, today],
      currentDate: today,
      searchQuery: null,
      dateRadioPicked: 1,
      reportTypePicked: 1,
      interval: null,
      selector: null,
      selectorItems: null
    }
  },
  components: {
    StatisticListGoods,
    StatisticListPreOrders,
    StatisticListServices,
    StatisticListGroup
  },
  computed: {
    projectId() {
      return this.$route.params.projectId
    },
    queryParams() {
      const params = new URLSearchParams()
      params.append('start', this.dateRange[0])
      params.append('end', this.dateRange[1])
      //params.append('split', 'month')
      return params
    },
    dateRangeText() {
      const dateFrom = this.$moment(this.dateRange[0])
      const dateTo = this.$moment(this.dateRange[1])
      return this.formatDateRangeText(dateFrom, dateTo)
    },
    periods() {
      const commonStats = this.salesStatistics.common
      return commonStats ? commonStats.periods : []
    },
    ...mapGetters('project', ['salesStatistics', 'isLoading'])
  },
  mounted() {
    this.setDateRange()
    this.interval = setInterval(() => this.setDateRange(), 60000)
    this.generateSelectorItems()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    updateSales() {
      this.fetchSalesStatistics({
        projectId: this.projectId,
        queryParams: this.queryParams
      })
    },
    onDatePickerRangeChange() {
      if (this.dateRange[1] < this.dateRange[0]) {
        this.dateRange.reverse()
      }
    },
    onDatePickerRangeSave() {
      if (this.dateRange.length === 1) {
        this.dateRange.push(this.currentDate)
      }
      this.$refs.dateRangePicker.save(this.dateRange)
      this.dateRadioPicked = 0
      this.selector = null
      this.updateSales()
    },
    onDatePickerRangeCancel() {
      this.isDateRangePickerVisible = false
    },
    setDateRange() {
      if (this.dateRadioPicked === 1) {
        this.dateRange = [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      } else if (this.dateRadioPicked === 2) {
        this.dateRange = [this.$moment().subtract(6, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      } else if (this.dateRadioPicked === 3) {
        this.dateRange = [this.$moment().subtract(1, 'days').format('YYYY-MM-DD'), this.$moment().subtract(1, 'days').format('YYYY-MM-DD')]
      } else if (this.dateRadioPicked === 4) {
        this.dateRange = [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      } else if (this.dateRadioPicked === 5) {
        this.dateRange = [this.$moment('2020-09-01').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      }
      this.selector = null
      this.updateSales()
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`
      }
      let dateFromFormat, dateToFormat
      dateFromFormat = dateToFormat = 'D MMM YYYY'
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM'
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D'
        } else {
          dateFromFormat = 'D MMM'
        }
      }

      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`
    },
    generateSelectorItems() {
      this.selectorItems = []
      let obj = {}
      let now = this.$moment().startOf('month')
      let start = this.$moment('2020-09-01')
      while (start <= now) {
        obj = {
          text: now.format('MMMM YYYY'),
          value: now.format('YYYY-MM-DD')
        }
        this.selectorItems.push(obj)
        now.subtract(1, 'month')
      }
    },
    onChangeSelector() {
      let lastDaySelector = this.$moment(this.selector.value).endOf('month')
      if (lastDaySelector > this.$moment()) {
        lastDaySelector = this.$moment()
      }
      this.dateRange = [this.$moment(this.selector.value).format('YYYY-MM-DD'), lastDaySelector.format('YYYY-MM-DD')]
      this.dateRadioPicked = 0
      this.updateSales()
    },
    ...mapActions('project', ['fetchSalesStatistics'])
  }
}
</script>
